import React from 'react';
import { ForceGraph3D } from 'react-force-graph';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import backgroundData from './data.json';
import Stack from 'react-bootstrap/Stack';
import logoWhite from './logo-white.svg'
import { BsGithub } from "react-icons/bs";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

// https://stackoverflow.com/a/38463360
const BackgroundGraph = styled.section`
    width: 100vw;
    height: 100vh;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -9999;
`;

function CareerModal(props) {
  return (
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            职位
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img style={{width: "100%"}} src={props.image} alt=""/>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>关闭</Button>
        </Modal.Footer>
      </Modal>
  );
}

function App() {

  const [feModalShow, setFeModalShow] = React.useState(false);
  const [beModalShow, setBeModalShow] = React.useState(false);
  const [devOpsMalShow, setDevOpsModalShow] = React.useState(false);

  const { useRef, useEffect } = React;

  const fgRef = useRef();

  useEffect(() => {
    const bloomPass = new UnrealBloomPass();
    bloomPass.strength = 1;
    bloomPass.radius = 1;
    bloomPass.threshold = 0;
    fgRef.current.postProcessingComposer().addPass(bloomPass);
  }, []);

  return (
      <>
        <style type="text/css">
          {`
          .dropdown>a::after {
            color: white;
          }
          .dropdown-menu {
            background-color: transparent;
            .dropdown-item:hover {
              text-decoration: underline;
              color: #fff;
              background-color: transparent;
            }
          }
        `}
        </style>

        {/* https://stackoverflow.com/a/69321054 */}
        <Stack direction="horizontal" gap={5} className='mt-5 mx-5'>
          <div className="p-2 text-white">
            <img src={logoWhite} alt='logo'/>
            Paion Data
          </div>

          <Nav className="justify-content-center">
            <NavDropdown title={<span style={{color: 'white'}}>C 端应用</span>} id="basic-nav-dropdown">
              <NavDropdown.Item href="https://wilhelmlang.com/">
                <span style={{color: 'white'}}>Wilhelm</span>
              </NavDropdown.Item>
              <NavDropdown.Item href="https://vk4501.paion-data.com/">
                <span style={{color: 'white'}}>VK-45.01</span>
              </NavDropdown.Item>
              <NavDropdown.Item href="https://alexandria.paion-data.com/">
                <span style={{color: 'white'}}>Alexandria</span>
              </NavDropdown.Item>
              <NavDropdown.Item href="https://app.nexusgraph.com/">
                <span style={{color: 'white'}}>Nexus Graph</span>
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={<span style={{color: 'white'}}>自然语言处理</span>} id="basic-nav-dropdown">
              <NavDropdown.Item href="https://github.com/paion-data/wiktionary-data">
                <span style={{color: 'white'}}>Wiktionary 单词数据</span>
              </NavDropdown.Item>
              <NavDropdown.Item href="https://github.com/paion-data/tiger">
                <span style={{color: 'white'}}>Tiger ASR</span>
              </NavDropdown.Item>
              <NavDropdown.Item href="https://github.com/paion-data/aristotle">
                <span style={{color: 'white'}}>Aristotle</span>
              </NavDropdown.Item>
              <NavDropdown.Item href="https://github.com/paion-data/lamassu">
                <span style={{color: 'white'}}>Lamassu</span>
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={<span style={{color: 'white'}}>开源</span>} id="basic-nav-dropdown">
              <NavDropdown.Item href="https://ui.paion-data.com/">
                <span style={{color: 'white'}}>Paion Storybook</span>
              </NavDropdown.Item>
              <NavDropdown.Item href="https://fastui.paion-data.com/">
                <span style={{color: 'white'}}>FastUI</span>
              </NavDropdown.Item>
              <NavDropdown.Item href="https://bertok.wilhelmlang.com/">
                <span style={{color: 'white'}}>Bertok</span>
              </NavDropdown.Item>
              <NavDropdown.Item href="https://fastspring.paion-data.com/">
                <span style={{color: 'white'}}>FastSpring</span>
              </NavDropdown.Item>
              <NavDropdown.Item href="https://hashistack.paion-data.dev/">
                <span style={{color: 'white'}}>软件 CI/CD 标准化工具</span>
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={<span style={{color: 'white'}}>社区</span>} id="basic-nav-dropdown">
              <NavDropdown.Item href="https://github.com/paion-data">
                <span style={{color: 'white'}}>GitHub</span>
              </NavDropdown.Item>
              <NavDropdown.Item href="https://hub.docker.com/u/paiondatahub">
                <span style={{color: 'white'}}>Docker Hub</span>
              </NavDropdown.Item>
              <NavDropdown.Item href="https://huggingface.co/paion-data">
                <span style={{color: 'white'}}>Hugging Face</span>
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={<span style={{color: 'white'}}>加入我们</span>} id="basic-nav-dropdown">
              <NavDropdown.Item>
                <span style={{color: 'white'}} onClick={() => setFeModalShow(true)}>前端开发</span>

                <CareerModal
                    show={feModalShow}
                    image="/img/frontend.png"
                    onHide={() => setFeModalShow(false)}
                />
              </NavDropdown.Item>
              <NavDropdown.Item>
                <span style={{color: 'white'}} onClick={() => setBeModalShow(true)}>后端开发</span>

                <CareerModal
                    show={beModalShow}
                    image="/img/backend.png"
                    onHide={() => setBeModalShow(false)}
                />
              </NavDropdown.Item>
              <NavDropdown.Item>
                <span style={{color: 'white'}} onClick={() => setDevOpsModalShow(true)}>运维开发</span>

                <CareerModal
                    show={devOpsMalShow}
                    image="/img/devops.png"
                    onHide={() => setDevOpsModalShow(false)}
                />
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>

          <div className="p-2 ms-auto text-white">
            <a href="https://github.com/paion-data" style={{color: '#ffffff'}}>
              <BsGithub
                  size={30}
                  onMouseOver={({target}) => target.style.color = '#0C6EFD'}
                  onMouseOut={({target}) => target.style.color = '#ffffff'}
              />
            </a>
          </div>
        </Stack>

        <div style={{position: 'absolute', left: '47%', bottom: "2%"}}>
          <a href="https://beian.miit.gov.cn/#/Integrated/index">粤ICP备2024234224</a>
        </div>

        <BackgroundGraph>
          <ForceGraph3D
              ref={fgRef}
              backgroundColor="#000003"
              graphData={backgroundData}
              nodeLabel={node => `${node.user}: ${node.description}`}
              nodeAutoColorBy="user"
              linkDirectionalParticles={1}
          />
        </BackgroundGraph>
      </>
  );
}

export default App;
